@import "../../utils/replica-id.scss";

.ModalStyle,
.ModalForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 24px;
    padding: 40px;
    outline: 0;
}

.Loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    p{
        margin: 0;
    }
}

.ModalForm {
    width: 400px !important;
}

.ModalStyle {
    width: fit-content;
}

.ModalTitle,
.ModalLoginTitle {
    font-family: $main-font;
    text-align: center;
}

.ModalTitle {
    font-weight: 400;
    font-size: 1.15rem;
    letter-spacing: 0em;
    margin: 15px 0;
}

.ModalLoginTitle {
    font-size: .8rem;
    letter-spacing: 0em;
    margin: 15px 0;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.InfoText {
    align-items: start;
    display: flex;
}

.InfoText,
.SendText {
    font-family: $main-font, sans-serif;
    gap: 5px;
    font-size: 0.7rem;
    line-height: 1.1rem;
}

.SendText a,
.SendText a:visited,
.SendText a:hover {
    color: initial;
}

.SendText {
    text-align: center !important;
    width: 100%;
}

.TermText {
    align-items: start !important;

    span {
        padding-top: 0 !important;
        font-family: $main-font,
            sans-serif;
        font-size: 0.7rem;
        line-height: 1.1rem;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .ModalTitle {
        font-size: 1.4rem;
    }

    .InfoText {
        font-size: .8rem;
        line-height: 1.3rem;
    }

    .TermText {
        span {
            font-size: .8rem;
            line-height: 1.3rem;
        }
    }

    .ModalForm,
    .ModalStyle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100% !important;
        top: 0;
        left: 0;
        transform: translate(0, 0);
        border-radius: 0;
        height: 100%;
    }
}