@import "../../utils/replica-id.scss";

.Formats {
  background-image: linear-gradient(90deg, rgb(218, 216, 216) 0%, rgb(255, 255, 255) 100%);
  background-image: -moz-linear-gradient(90deg, rgb(218, 216, 216) 0%, rgb(255, 255, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(218, 216, 216) 0%, rgb(255, 255, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(218, 216, 216) 0%, rgb(255, 255, 255) 100%);
  height: 350vh;
  width: 100%;
  padding-left: 8vw;
  padding-right: 2vw;
  position: relative;
}

.FormatsSticky {
  padding: 10vh 0;
  position: sticky;
  top: 0;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  gap: 50px;
}

.Title {
  font-family: $main-font, 'sans-serif';
  font-weight: 500;
  font-size: 1.8rem;
  width: 100%;
  text-align: center;
  color: $gray;
  padding-right: 50px;
  opacity: 0;
}

.TitleMotion {
  animation: slideFromBottom 1s forwards 0.5s;
}

.FormatsTitlesMotion {
  animation: slideFromBottom 1s forwards 0.5s;
}

.MediaLogos1,
.MediaLogos2,
.MediaLogos3,
.MediaLogos4,
.MediaLogos5 {
  animation: slideFromBottom 1s forwards;
  opacity: 0;
}

.MediaLogos1 {
  animation-delay: 0.7s;
}

.MediaLogos2 {
  animation-delay: 0.8s;
}

.MediaLogos3 {
  animation-delay: 1s;
}

.MediaLogos4 {
  animation-delay: 1.3s;
}

.MediaLogos5 {
  animation-delay: 1.5s;
}

@keyframes slideFromBottom {
  from {
    transform: translateY(50%);
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.FormatsTitles {
  width: 80%;
  background-image: linear-gradient(90deg, rgb(169, 196, 202, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0 50px;
  margin-top: 50px;
  opacity: 0;
}

.FormatsList {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FormatsColumn {
  font-family: $main-font, 'sans-serif';
  font-weight: normal;
  color: $gray;
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    width: 45%;
    padding: 20px 0;
    padding-right: 40px;
    border-bottom: thin solid #65656568;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1.5vw;
    transition: all 0.2s linear;
  }

  div:nth-last-child(1),
  div:nth-last-child(2) {
    border-bottom: none;
  }

  div:hover {
    font-style: italic;
    font-weight: bold;
  }
}

.ArrowIcon {
  width: 1em;
  height: auto;
}

.HighlightText {
  font-family: $main-font, 'sans-serif';
  font-style: italic;
  font-weight: 300;
  text-decoration: underline;
}

.MediaLogos {
  padding-right: 150px;
  padding-left: 50px;
  padding-bottom: 30px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 50px;

  img {
    height: 28px;
    width: auto;
  }

  p {
    font-weight: bold;
    font-family: $main-font, 'sans-serif';
    font-size: 25px;
    margin: 0;
  }
}

.ArchiveIcon {
  width: 5em;
  margin: 25px;
}

.CTAButton {
  width: 100%;
  padding-right: 100px;
  text-align: center;
  z-index: 5;

  a {
    background-image: linear-gradient(90deg, rgba(169, 196, 202, 0.7) 0%, rgb(169, 196, 202, 0.5) 100%);
  }

  a:hover {
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%);
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .Formats {
    padding: 0 40px;
    height: 150vh;
  }

  .FormatsSticky {
    padding: 40px 0;
    justify-content: space-between;
    min-height: 70vh;
    max-height: 100vh;
    gap: 0;
  }

  .Title {
    font-size: 5.5vw;
    width: 100%;
    padding-right: 0;
  }

  .FormatsTitles {
    width: 100%;
    flex-direction: column;
    border-radius: 80px;
    gap: 20px;
    padding: 20px 5px;
  }

  .ArchiveIcon {
    width: 3em;
    height: fit-content;
    margin: 5px;
  }

  .FormatsList {
    display: flex;
    flex-direction: row;
    width: 80%;
  }

  .FormatsColumn {
    flex-direction: column;
    font-size: 4vw;

    div {
      width: 100%;
      padding: 10px;
      font-size: 5vw;
    }

    div:nth-last-child(1),
    div:nth-last-child(2) {
      border-bottom: thin solid #65656568;
    }
  }

  .MediaLogos {
    padding: 0;
    padding-right: 0;
    padding-bottom: 20px;
    height: fit-content;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    img {
      height: 24px;
      width: auto;
    }

    p {
      font-size: 22px;
      line-height: 25px;
    }
  }

  .CTAButton {
    padding-right: 0;
  }
}


@media screen and (min-width: $wide-breakpoint) {
  .Formats {
    height: 250vh;
    padding-left: 12vw;
    padding-right: 0vw;
  }

  .FormatsSticky {
    gap: 100px;
    padding: 10vh 0;
  }

  .Title {
    font-size: 1.5vw;
    text-align: center;
    width: 100%;
    color: $gray;
    padding-right: 10vw;
  }

  .FormatsTitles {
    width: 80%;
    gap: 4vw;
    padding: 0 4vw;
  }

  .FormatsColumn {
    font-size: 1.5rem;

    div {
      width: 40%;
      padding: 1.5vw 0;
      padding-right: 4vw;
    }
  }

  .ArrowIcon {
    width: 1.2em;
  }

  .MediaLogos {
    padding-right: 15vw;
    padding-bottom: 1vw;
    width: 90%;
    height: fit-content;

    img {
      height: 1.5vw;
    }

    p {
      font-size: 1.5vw;
    }
  }

  .CTAButton {
    padding-right: 15vw;
  }
}