@import "../../utils/replica-id.scss";

.ResultContainer {
    position: sticky;
    width: 46%;
    align-self: flex-end;
    padding-bottom: 5vh;
}

.PriceTitle,
.PriceText {
    font-family: $main-font, sans-serif;
}

.PriceTitle {
    font-size: 16px;
    font-weight: 400;
}

.PriceText {
    font-size: 36px;
    font-size: clamp(36px, 15vw, 80px);
    transition: font-size 0.3s ease;
    white-space: nowrap;
}

.smallest {
    font-size: 4rem;
}

.small {
    font-size: 5rem;
}

.medium {
    font-size: 5.5rem;
}

.large {
    font-size: 6.5rem;
}


@media screen and (min-width: $wide-breakpoint) {
    .ResultContainer {
        padding-bottom: 3vh;
    }

    .small {
        font-size: 8rem;
    }

    .medium {
        font-size: 10rem;
    }

    .large {
        font-size: 11rem;
    }

    .smallest {
        font-size: 7rem;
    }

    .ResultContainer {
        width: 42%;
    }

    .PriceTitle {
        font-size: 22px;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .ResultContainer {
        padding: 20px;
        position: sticky;
        left: 0;
        bottom: 0;
        align-self: center;
        width: 112%;
        background-color: white;
    }

    .smallest {
        font-size: 2.5rem;
    }

    .small {
        font-size: 3rem;
    }

    .medium {
        font-size: 3.5rem;
    }

    .large {
        font-size: 4rem;
    }

    .PriceTitle {
        font-size: 18px;
    }
}