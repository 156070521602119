@import "../../utils/replica-id.scss";

.Section {
    background-color: $pale-blue;
    width: 100%;
    height: 500vh;
    top: 0;
    z-index: 1;
    position: relative;
    padding: 3vw 0;
    padding-bottom: 0;
}

.SectionSticky {
    width: 100%;
    height: 100vh;
    top: 0;
    position: sticky;
    overflow: hidden;
}

.ProgressBars {
    padding: 2vh 3vh ;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
}

.GifContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $pale-blue;
    width: 102%;
}

.GifContainer img {
    width: 100%;
    // border: 1px solid black;
    opacity: 1;
}

.StepTitle {
    font-family: $main-font, "sans-serif";
    color: $white;
    font-weight: 500;
    text-align: center;
    font-size: 2.8vh;
    padding: 1.5vh 0;
}


@media screen and (min-width: $wide-breakpoint) {

    .GifContainer img {
        width: 100%;
    }
}

@media screen and (max-width: $mobile-breakpoint) {

    .SectionSticky {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .ProgressBars {
        width: 100%;
        margin-bottom: auto;
    }

    .GifContainer {
        margin-bottom: auto;
    }

    .GifContainer img {
        width: 115%;
    }

    .StepTitle {
        font-size: 5.5vw;
        width: 90%;
        min-height: 120px;
        margin-top: -10%;
    }

}