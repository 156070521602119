@import "../../utils/replica-id.scss";

@keyframes appearInDesktop {
  from {
    opacity: 0;
    transform: translateY(8vh) translateX(22vw) scale(0);
    transform-origin: center left;
  }

  to {
    opacity: 1;
    transform:translateY(8vh) translateX(22vw) scale(1.5);
    transform-origin: center left;
  }
}

@keyframes positionEnd {
  from {
    transform: translateY(8vh) translateX(22vw) scale(1.5);
    transform-origin: center left;
  }

  to {
    transform: translateY(0) translateX(0) scale(1);
    transform-origin: center left;
  }
}

@keyframes appearOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideToLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slideFromTop {
  from {
    transform: translateY(-150%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes appearInMobile {
  from {
    opacity: 0;
    transform: translateY(50%) translateX(0%) scale(0);
    transform-origin: center;
  }

  to {
    opacity: 1;
    transform: translateY(25%) translateX(0%) scale(1);
    transform-origin: center;
  }
}

.HeaderContainer {
  position: sticky;
  min-height: 100vh;
  background-color: $pale-blue;
  top: 0;
  opacity: 0;
  transition: all 1s ease;
}

.visible {
  opacity: 1;
}

.Header {
  background-color: $pale-blue;
  height: fit-content;
  padding-top: 20px;
  padding-left: 100px;
  padding-bottom: 50px;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: start;
  justify-content: start;
  overflow-y: hidden;
}

.HeaderText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: fit-content;
  width: fit-content;
  gap: 40px;
  margin-top: 30px;
  white-space: nowrap;
}

.HeaderImg {
  max-width: 60vw;
  height: auto;
  z-index: 2;
  position: relative;
  animation: slideToLeft 0.8s forwards 2.8s cubic-bezier(.24, .78, .7, 1.03);
  transform: translateX(150%);

  .Img {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
  }
}

.WhiteBlur {
  width: 50vw;
  height: 50vw;
  border-radius: 100%;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, rgb(169, 196, 202, 0) 50%, rgba(169, 196, 202, 0) 100%);
  position: absolute;
  top: -25%;
  left: -30%;
}

.HighlightText {
  text-decoration: underline;
  font-weight: 300;
  font-style: italic;
}

.Title {
  color: $primary-text;
  font-family: $main-font, 'sans-serif';
  font-size: 3em;
  animation: appearInDesktop 1s forwards 1.5s, positionEnd 1s forwards 2.5s;
  opacity: 0;
  width: 500px;
}

.Subtitle {
  color: $secondary-text;
  max-width: 350px;
  font-weight: 100;
  font-size: 1em;
  font-family: $main-font, 'sans-serif';
  margin-bottom: 2em;
}

.AppearOpacity {
  animation: appearOpacity 0.5s forwards 3s;
  opacity: 0;
}

@media screen and (max-width: $mobile-breakpoint) {
  .Header {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 70px;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    min-height: fit-content;
  }

  .HeaderImg {
    width: 100%;
    max-width: 70vw;
    animation-name: slideFromTop;
    animation-delay: 2s;
  }

  .WhiteBlur {
    width: 100vw;
    height: 100vw;
    top: -20vw;
    left: -25vw;
  }

  .HeaderText {
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .Title {
    font-size: 9vw;
    text-align: center;
    animation: appearInMobile 1s forwards 2.5s;
    margin-bottom: 40px;
    min-width: 200px;
  }

  .Subtitle {
    max-width: 100%;
    font-size: 4vw;
    font-family: $main-font, 'sans-serif';
    text-align: center;
  }

  .AppearOpacity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation-delay: 3s;
  }
}

@media screen and (min-width: $wide-breakpoint) {
  .Header {
    padding: 2vw 0;
    padding-left: 12vw;
    gap: 12vw;
    padding-top: 3vw;
    padding-bottom: 5vw;
  }

  .Title {
    font-size: 5em;
    width: 600px;
  }

  .Subtitle {
    font-size: 1.5em;
  }

  .HeaderImg {
    max-width: 60vw;
  }

  .HeaderText {
    gap: 60px;
    margin-top: 3vw;
  }
}